import React, { useEffect, useState } from 'react';
import Layout from "../components/layout/layout";
import SEO from "../components/layout/seo";
import * as styles from "./category.module.css"
import { MdExpandMore } from "@react-icons/all-files/md/MdExpandMore";
import { MdExpandLess } from "@react-icons/all-files/md/MdExpandLess";
import Articles from "../components/articles/articles";
import CategoryField from '../components/CategoryField';
import DownloadFileList from '../components/DownloadFileList';
import BasicLayout from "../components/BasicLayout";
import Header from "../components/Header";

let groupsData = [
    {
        slug: 'vyru-sportine-gimnastika',
        expanded: false,
        sections: [
            { name: 'Apie', id: 'apie' },
            { name: 'Naujienos', id: 'naujienos' },
            { name: 'Taisyklės', id: 'taisykles' },
            { name: 'Kalendorius', id: 'kalendorius' },
            { name: 'Rinktinės', id: 'rinktines' },
            { name: 'Amžiaus grupės', id: 'amziausGrupes' }
        ]
    },
    {
        slug: 'moteru-sportine-gimnastika',
        expanded: false,
        sections: [
            { name: 'Apie', id: 'apie' },
            { name: 'Naujienos', id: 'naujienos' },
            { name: 'Taisyklės', id: 'taisykles' },
            { name: 'Kalendorius', id: 'kalendorius' },
            { name: 'Rinktinės', id: 'rinktines' },
            { name: 'Amžiaus grupės', id: 'amziausGrupes' }
        ]
    },
    {
        slug: 'menine-gimnastika-1',
        expanded: false,
        sections: [
            { name: 'Apie', id: 'apie' },
            { name: 'Naujienos', id: 'naujienos' },
            { name: 'Taisyklės', id: 'taisykles' },
            { name: 'Kalendorius', id: 'kalendorius' },
            { name: 'Rinktinės', id: 'rinktines' },
            { name: 'Amžiaus grupės', id: 'amziausGrupes' }]
    },
    {
        slug: 'aerobine-gimnastika',
        expanded: false,
        sections: [
            { name: 'Apie', id: 'apie' },
            { name: 'Naujienos', id: 'naujienos' },
            { name: 'Taisyklės', id: 'taisykles' },
            { name: 'Kalendorius', id: 'kalendorius' },
            { name: 'Rinktinės', id: 'rinktines' },
            { name: 'Amžiaus grupės', id: 'amziausGrupes' }]
    },
    {
        slug: 'batutas-ir-akrobatinis-takelis',
        expanded: false,
        sections: [
            { name: 'Apie', id: 'apie' },
            { name: 'Naujienos', id: 'naujienos' },
            { name: 'Taisyklės', id: 'taisykles' },
            { name: 'Kalendorius', id: 'kalendorius' },
            { name: 'Rinktinės', id: 'rinktines' },
            { name: 'Amžiaus grupės', id: 'amziausGrupes' }]
    },
    {
        slug: 'sportine-akrobatika',
        expanded: false,
        sections: [
            { name: 'Apie', id: 'apie' },
            { name: 'Naujienos', id: 'naujienos' },
            { name: 'Taisyklės', id: 'taisykles' },
            { name: 'Kalendorius', id: 'kalendorius' },
            { name: 'Rinktinės', id: 'rinktines' },
            { name: 'Amžiaus grupės', id: 'amziausGrupes' }]
    },
    {
        slug: 'gimnastika-visiems',
        expanded: false,
        sections: [
            { name: 'Apie', id: 'apie' },
            { name: 'Naujienos', id: 'naujienos' },
            { name: 'Kalendorius', id: 'kalendorius' },
        ]
    }]

const Category = ({ data, classes, pageContext }) => {
    const [groups, setGroups] = useState([]);
    let category = data.strapiCategory;
    let categories = data.allStrapiCategory.nodes;
    useEffect(() => {
        groupsData.forEach(g => {
            let found = categories.find(category => category.slug === g.slug);
            g.name = found.name;
            g.expanded = false;
            if (g.slug === pageContext.slug) {
                g.expanded = true;
            }
        })

        setGroups(groupsData)
    }, [])

    const onClick = (name) => {
        groups.forEach(g => g.expanded = false);
        setGroups(groups.map(g => { if (g.name == name) g.expanded = true; return g; }))
    }

    return <Layout>
        <SEO
            seo={{
                metaTitle: "gimnastika.lt | Vyrų sportinė gimnastika",
            }}
        />

        <Header id="pradzia" name={category.name} />

        <BasicLayout
            left={
                <>
                    {<CategoryField id="apie" header="APIE" markdown={true} content={category.apie} />}
                    {<CategoryField id="naujienos" header="NAUJIENOS" content={<Articles articles={data.allStrapiArticle.nodes} />} />}
                    {pageContext.slug !== 'gimnastika-visiems' && <CategoryField id="taisykles" header="TAISYKLĖS" markdown={true} content={category.taisykles} />}
                    {<CategoryField id="kalendorius" header="KALENDORIUS" content={<DownloadFileList documents={category?.kalendoriai} />} />}
                    {pageContext.slug !== 'gimnastika-visiems' && <CategoryField id="rinktines" header="RINKTINĖS" content={<DownloadFileList documents={category?.rinktines} />} />}
                    {pageContext.slug !== 'gimnastika-visiems' && <CategoryField id="amziausGrupes" header="AMŽIAUS GRUPĖS" content={<DownloadFileList documents={category?.amziausGrupes} />} />}
                </>}
            right={
                <>
                    <div className={styles.container} style={{ flex: '1 1 300px', justifyContent: 'center', alignSelf: 'flex-start' }}>
                        <div className={styles.box}>
                            {groups.map((g, i) =>
                                <div key={i} >
                                    <div className={styles.group} onClick={() => onClick(g.name)}>
                                        <div>{g.name}</div>
                                        <div className={styles.icon}>
                                            {!g.expanded && <MdExpandMore size={22} />}
                                            {g.expanded && <MdExpandLess size={22} />}
                                        </div>
                                    </div>
                                    {g.expanded && <div className={styles.children}>
                                        {g.sections.map((s, i) => <a href={`/${g.slug}#${s.id}`} key={i}><div>{s.name}</div></a>)}
                                    </div>}
                                </div>)}
                        </div>
                    </div>

                </>
            } />
    </Layout>

};

export default Category;

export const query = graphql`
  query ArticleCategory($slug: String!) {
    strapiCategory(slug: { eq: $slug } ) {  
        name
        slug
        taisykles
        amziausGrupes {
            name
            url
        }
        apie
        kalendoriai {
            name
            url
        }
        rinktines {
            name
            url
        }
        articles {
            slug
            title
            publishedAt(formatString: "LL", locale: "lt")
            category
            image {
                childImageSharp {
                    fixed(width: 700, height: 500) {
                        src
                    }
                }
            }
        }
    }
    allStrapiArticle(sort: {fields: publishedAt, order: DESC}, limit: 6, filter: {category: {slug: {eq: $slug}}}) {
        nodes {
            slug
            title
            publishedAt(formatString: "LL", locale: "lt")
            category {
                name
            }
            image {
                childImageSharp {
                    fixed(width: 700, height: 500) {
                        src
                    }
                }
            }
        }
      }
    allStrapiCategory {
        nodes {
          name
          slug
        }
      }
  }
`;
