import React from 'react'
import * as styles from "./CategoryField.module.css"
import Markdown from "react-markdown";

export default function CategoryField({ id, header, content, markdown }) {
    return (
        <div className={styles.section} id={id}>
            <h3 style={{ marginTop: '0px' }}>{header}</h3>
            <div className={styles.about}>
                {markdown && <Markdown source={content} escapeHtml={false} />}
                {!markdown && <>{content}</>}
            </div>
        </div>
    )
    
}